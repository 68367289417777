*,
body,
html {
  margin: 0;
  padding: 0;
  line-height: 1em;
  font-weight: 300;
  font-family: 'Inter', sans-serif;
  -webkit-backface-visibility: hidden;
}
